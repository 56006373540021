import React from 'react';
import Button from '../../components/Button/Button';
import SubTitle from '../../components/Text/SubTitle/SubTitle';
import './about.scss';
import {motion} from 'framer-motion';

const animate = {
    hidden: {
        scale: 0.5,
        opacity: 0,
        y: 10,
        transition: {
            type: 'scale',
            stiffness: 300,
            damping: 140,
        },
    },
    show: {
        scale: 1,
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01]
        }
    },
};

const About = () => (
    <motion.div variants={animate} initial="hidden" whileInView="show" viewport={{amout: 0.1, once: true,}} className='about'>
        <motion.div  className='about__title'>
            Про себе
        </motion.div>
        <motion.div
            className='about__info'
        >
            <motion.div className='about__left'>
                <motion.div  className='about__subtitle'>
                    <SubTitle>
                        Дипломований реабілітолог зі стажем більше 10 років.
                        Спеціалізуюся в лікувальному масажі від грудного віку до літнього,
                        а також у реабілітації після інсульту, перелому хребта, спортивних травм
                        та інших захворювань опорно-рухового апарату (ОРА). За роки роботи реабілітологом,
                        напрацював величезний досвід лікування ОРА, та побудував власну методику лікування з
                        комплексним підходом, що сприяє швидкому одужанню.
                    </SubTitle>
                </motion.div>
                <motion.div   className='about__btn'>
                    <a href='https://docs.google.com/forms/d/1PaT6OiPLtKgrAvfYbDyfBEXfSbQ0tJM76UZ8D6usu60/' target="_blank">
                        <Button>Записатись</Button>
                    </a>
                </motion.div>
            </motion.div>
            <motion.img  custom={1} variants={animate} className='about__img'/>
        </motion.div>
    </motion.div>
);

export default About
